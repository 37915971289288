import {graphql} from "gatsby";
import Container from "../../../primitives/grid/container";
import Row from "../../../primitives/grid/row";
import ProductCard from "../../../product/product-card";
import React from "react";
import Col from "../../../primitives/grid/col";
import Pagination from "../../../primitives/pagination";
import {ProductRenderDisplayVariant} from "../../../../importer/customizer/utils/get-variants-for-display";
import {colors} from "../../../primitives/colors";
import Typography from "../../../primitives/typography";
import {SortSelector} from "../../../product/swatch-selector";
import Background from "../../../primitives/background";
import StyledButton, {styledButtonBaseStyles} from "../../../primitives/styled-button";
import AnimatedCheckbox from "../../../primitives/animated-checkbox";
import {ParentIncludingProductTag} from "../../../../importer/components/product-editor";
import {useAllProductsContext} from "../../../../contexts/all-products-context";
import {useProductCategory} from "../../../../contexts/product-category-context";
import useIconsQuery from "../../../../hooks/use-icons-query";
import {breakpoints} from "../../../primitives/tokens";
import {StyledLinkStyles} from "../../../primitives/styled-link";
import {AnimateChangeInHeight} from "../../../animations/components/animate-change-in-height";
import Hamburger from "../../../hamburger";

type SortType ='recommended'|'price-low'|'price-high'|'new-arrivals'
export const sortOptions: { label: string, value: SortType }[] = [{
    label: 'Recommended',
    value: 'recommended'
}, {
    label: 'Price, Low to High',
    value: 'price-low'
}, {
    label: 'Price, High to Low',
    value: 'price-high'
}, {
    label: 'New Arrivals',
    value: 'new-arrivals'
}]

interface FilterableOptionGroup {
    title: string
    id: string
    options: Record<string, FilterableOption>
}
interface FilterableOption {
    title: string
    id: string
    parentId: string
    selected: boolean
    image?: any
    invertImage?: any
}

const buildCategoryAndFurnishType = (x: ProductRenderDisplayVariant): ParentIncludingProductTag[] => ([
    {
        title: x.productTitle,
        id: x.productDefinitionOriginalId,
        parentGroupId: '**PRODUCT',
        parentGroupTitle: 'Category',
        isFilterable: true,
        image: x.icon,
        invertImage: x.iconInvert,
    },
    {
        title: x.furnishingTypeTitle,
        id: x.furnishingTypeDefinitionOriginalId,
        parentGroupId: '**FURNISHING_TYPE',
        parentGroupTitle: 'Furnishing Type',
        isFilterable: true,
        image: x.furnishingTypeIcon,
        invertImage: x.iconInvert,
    }
])

const filterProducts = (originalProducts: ProductRenderDisplayVariant[], currentTags: Record<string, string[]>) => {
    return originalProducts.filter(x => {
        const tags = x.tags;

        const allTags: ParentIncludingProductTag[] = [
            ...tags,
            ...buildCategoryAndFurnishType(x)
        ]

        return Object.entries(currentTags).every(([groupId, values]) => {
            if (!values) {
                return true
            }
            const foundGroup = allTags.find(x => x.parentGroupId === groupId)
            if (foundGroup) {
                return values.some(x => {
                    const found = allTags.find(y => y.id === x)
                    if (found) {
                        return true
                    }
                })
            }
            return false
        })
    })
}

const getTagsFromProducts = (originalProducts: ProductRenderDisplayVariant[], filteredProducts: ProductRenderDisplayVariant[], currentTags: Record<string, string[]>): Record<string, FilterableOptionGroup> => {
    const currentTagLength = Object.values(currentTags).length

    return originalProducts.reduce(((acc, val) => {
        ([
            ...val.tags,
            ...buildCategoryAndFurnishType(val)
        ]).forEach((tag) => {
            if (tag.isFilterable) {
                if (!acc[tag.parentGroupId]) {
                    acc[tag.parentGroupId] = {
                        title: tag.parentGroupTitle,
                        id: tag.parentGroupId,
                        options: {}
                    }
                }

                if (currentTagLength === 1 && currentTags[tag.parentGroupId]) {
                    acc[tag.parentGroupId].options[tag.id] = {
                        selected: !!currentTags?.[tag.parentGroupId]?.includes(tag.id),
                        title: tag.title,
                        id: tag.id,
                        parentId: tag.parentGroupId,
                        image: tag.image,
                        invertImage: tag.invertImage,
                    }

                } else {

                    acc[tag.parentGroupId].options[tag.id] = {
                        selected: !!currentTags?.[tag.parentGroupId]?.includes(tag.id), //&& !!allCurrentTagsForFilterVariants.find(x => x.id === tag.id),
                        title: tag.title,
                        id: tag.id,
                        parentId: tag.parentGroupId,
                        image: tag.image,
                        invertImage: tag.invertImage,
                    }
                }

            }
        })

        return acc
    }), {} as Record<string, FilterableOptionGroup>)
}

const performSort = (sort: SortType, items: ProductRenderDisplayVariant[]): ProductRenderDisplayVariant[] => {
    if (sort === 'recommended') {
        let longestColorLength = 0;
        const toFabrics = items.reduce((acc, val) => {
            if (!acc[val.fabricTitle]) {
                acc[val.fabricTitle] = []
            }
            acc[val.fabricTitle].push(val)
            if (acc[val.fabricTitle].length > longestColorLength) {
                longestColorLength = acc[val.fabricTitle].length
            }
            return acc
        }, {} as Record<string, ProductRenderDisplayVariant[]>)

        const sorted: [string, ProductRenderDisplayVariant[]][] = Object.entries(toFabrics).map(x => [x[0], x[1].sort((a, b) => a.numericPrice - b.numericPrice)])

        const positionList: Record<string, ProductRenderDisplayVariant[]> = {};

        for (let i = 0; i < longestColorLength; i++) {
            positionList[i] = []
            sorted.forEach(([k, items]) => {
                if (items[i]) {
                    positionList[i].push(items[i])
                }
            })
        }

        return Object.entries(positionList).flatMap(([k, v]) => {
            return v
        })
    }
    if (sort === 'price-low') {
        return items.sort((a, b) => a.numericPrice - b.numericPrice)
    }
    if (sort === 'price-high') {
        return items.sort((a, b) => b.numericPrice - a.numericPrice)
    }
    if (sort === 'new-arrivals') {
        return items
    }
    return items
}
const ProductList = ({ linkedProducts, searchQuery }) => {
    const [show, setShow] = React.useState(false)
    const [offset, setOffset] = React.useState(0)
    const [sort, setSort] = React.useState<SortType>("recommended")
    const [currentFilters, setCurrentFilters] = React.useState<Record<string, string[]>>({})
    const [uisShown, setUisShown] = React.useState<string[]>([])


    const {
        filteredVariants: allVariantsObj
    } = useAllProductsContext()

    const maybeProductCategory = useProductCategory()
    const icons = useIconsQuery();

    const quotedVariantsArray = Object.values(allVariantsObj);

    const {
        productDataSource,
        hideCategoryUi,
    } = (() => {
        if (linkedProducts?.length > 0) {
            const productIds = linkedProducts.map(x => x.originalId)
            return {
                productDataSource: quotedVariantsArray.filter(x => productIds.includes(x.productDefinitionOriginalId)),
                hideCategoryUi: true,
            }
        }
        if (maybeProductCategory?.originalId) {
            return {
                productDataSource: quotedVariantsArray.filter(x => x.productDefinitionOriginalId === maybeProductCategory.originalId),
                hideCategoryUi: true
            }
        }
        return {
            productDataSource: quotedVariantsArray,
            hideCategoryUi: false
        }
    })()

    const resultsRef = React.useRef<HTMLDivElement>(null)

    const filteredProducts = performSort(sort, filterProducts(productDataSource, currentFilters)).filter(x => {
        if (!searchQuery) {
            return true
        }
        return `${x.fabricTitle}${x.productTitle}${x.colorTitle}`.toLowerCase().includes(searchQuery.toLowerCase().split(" ").join(""))
    })

    const buildableTags = getTagsFromProducts(productDataSource, filteredProducts, currentFilters)

    const furnishingTypeGroup = buildableTags["**FURNISHING_TYPE"]
    const productGroup = buildableTags["**PRODUCT"]

    const perPage = 48;
    const pageCount = Math.ceil(filteredProducts.length / perPage)
    const page = (offset / perPage) + 1
    const startPoint = (page - 1) * perPage;

    const nodesSliced = (() => {
        return filteredProducts.slice(startPoint, startPoint + perPage);
    })();

    const applyFilter = (groupId: string, selfId: string) => {
        setOffset(0)

        let newCurrentFilters = {
            ...currentFilters,
        }

        if (currentFilters?.[groupId]?.includes(selfId)) {
            newCurrentFilters = {
                ...Object.entries(newCurrentFilters).reduce((acc, [k,v]) => {
                    if (k === groupId) {
                        if (v.filter(x => x !== selfId).length === 0) {
                            return acc
                        }
                        acc[k] = v.filter(x => x !== selfId)
                    } else {
                        acc[k] = v
                    }
                    return acc;
                }, {} as Record<string, string[]>)
            }

            // calculate if only remaining options are disabled

            if (newCurrentFilters[groupId]) {
                const allAreDisabled = newCurrentFilters[groupId].every(x => filterProducts(productDataSource, {
                    ...currentFilters,
                    [groupId]: [x]
                }).length === 0)

                if (allAreDisabled) {
                    newCurrentFilters = {
                        ...Object.entries(newCurrentFilters).reduce((acc, [k,v]) => {
                            if (k === groupId) {
                                return acc
                            } else {
                                acc[k] = v
                            }
                            return acc;
                        }, {} as Record<string, string[]>)
                    }
                }
            }

        } else {
            newCurrentFilters = {
                ...newCurrentFilters,
                [groupId]: [...(newCurrentFilters[groupId] || []), selfId]
            }
        }

        const computeProducts = filterProducts(productDataSource, newCurrentFilters)
        const newTags = getTagsFromProducts(productDataSource, computeProducts, currentFilters)

        newCurrentFilters = Object.entries(newCurrentFilters).reduce((acc, [k, v]) => {
            const newOptions = Object.values(newTags[k].options)
            acc[k] = v.filter(x => {
                const relevantOption = newOptions.find(y => y.id === x);

                return true
            })
            return acc;
        }, {} as Record<string, string[]>)

        setCurrentFilters(newCurrentFilters)
    }

    const entryTags = Object.entries(buildableTags);

    const activeFilters = entryTags
        .map(x => x[1])
        .flatMap(x => Object.values(x.options))
        .filter(x => x.selected)


    const hasFilterOptions = entryTags.map(x => x[1]).filter(x => {
        if (x.id === "**FURNISHING_TYPE" || x.id === "**PRODUCT") {
            return Object.keys(x.options).length > 1
        }
        return Object.keys(x.options).length > 0
    }).length > 0

    if (nodesSliced.length === 0) {
        return (
            <Container css={{
                marginTop: '100px',
                marginBottom: '100px',
                textAlign: 'center',
                [breakpoints.tb]: {
                    marginTop: '60px',
                    marginBottom: '60px'
                }
            }}>
                <Typography fontSize={{
                    dt: "h3",
                    tb: "h4",
                    mb: "h5"
                }} css={{
                    marginBottom: '20px'
                }}>
                    😔 No results found for your measurements.
                </Typography>
                <Typography fontSize={{
                    dt: "s1",
                }}>
                    Double check your measurements, or maybe try a different product.
                </Typography>
            </Container>
        )
    }

    return (
        <div>
            {hasFilterOptions && (
                <Container css={{
                    marginTop: '20px',
                    marginBottom: '56px',
                    [breakpoints.tb]: {
                        marginTop: '16px',
                        marginBottom: '28px'
                    }
                }}>
                    <Typography fontSize={{
                        dt: "s1"
                    }} css={{
                        display: 'none',
                        [breakpoints.tb]: {
                            display: 'block',
                            marginBottom: '16px',
                        }
                    }} color="neutral10">
                            <span css={{
                                color: colors.primaryOrange,
                            }} className="medium">
                                {filteredProducts.length}
                            </span>
                        {" "}
                        results
                    </Typography>
                    <div ref={resultsRef} css={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                        paddingBottom: '16px',
                        justifyContent: 'space-between',
                    }}>
                        <div css={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px',
                        }}>
                            <Typography fontSize={{
                                dt: "s1"
                            }} color="neutral10" css={{
                                [breakpoints.tb]: {
                                    display: 'none'
                                }
                            }}>
                            <span css={{
                                color: colors.primaryOrange,
                            }} className="medium">
                                {filteredProducts.length}
                            </span>
                                {" "}
                                results
                            </Typography>
                            <button type="button" css={{
                                border: `1px solid ${colors.neutral5}`,
                                borderRadius: '500px',
                                padding: '8px 24px',
                                width: '140px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                transition: 'background-color 0.2s',
                                height: '40px',
                                '&:hover': {
                                    backgroundColor: colors.neutral1
                                },
                            }} onClick={() => {
                                setShow(!show)
                            }}>
                                <Typography fontSize="p1" color="neutral10">
                                    Filter
                                </Typography>
                                <Hamburger
                                    as="div"
                                    open={show}
                                    setOpen={setShow}
                                />
                            </button>
                        </div>
                        <div css={{
                            width: '100%',
                            maxWidth: '270px'
                        }}>
                            <SortSelector sorts={sortOptions} value={sort} setValue={(e) => {
                                setSort(e as typeof sort)
                            }} />
                        </div>
                    </div>
                    <div css={{
                        position: 'relative',
                        width: '100%',
                        zIndex: 2,
                    }}>
                        <div css={{
                            width: '100%',
                        }}>
                            <AnimateChangeInHeight css={{
                                position: 'relative',
                                width: '100%'
                            }}>
                                <div css={{
                                    width: '100%',
                                }} style={{
                                    height: show ? 'auto' : '0'
                                }}>
                                    <div css={{
                                        //paddingTop: '28px',
                                        width: '100%',
                                    }}>
                                        <Background backgroundColor="secondaryBeige2">
                                            <div css={{
                                                display: 'flex',
                                                gap: '28px',
                                                paddingTop: '28px',
                                                paddingLeft: '28px',
                                                paddingRight: '28px',
                                                paddingBottom: '20px',
                                                [breakpoints.tb]: {
                                                    paddingLeft: '20px',
                                                    paddingRight: '20px',
                                                    flexDirection: 'column',
                                                    gap: '16px',
                                                }
                                            }}>
                                                {!hideCategoryUi && (
                                                    <div css={{
                                                        width: '20%',
                                                        [breakpoints.tb]: {
                                                            width: '100%',
                                                        }
                                                    }}>
                                                        <Typography fontSize={{
                                                            dt: "p1"
                                                        }} role="button" onClick={() => {
                                                            if (uisShown.includes("furnishing-type")) {
                                                                setUisShown((e) => e.filter(x => x !== "furnishing-type"))
                                                            } else {
                                                                setUisShown((e) => [...e, "furnishing-type"])
                                                            }
                                                        }} color="shadesBlack" css={{
                                                            width: '100%',
                                                            paddingBottom: '12px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            fontWeight: '500',
                                                            height: '33px',
                                                            [breakpoints.tb]: {
                                                                borderBottom: `1px solid ${colors.secondaryBeige4}`,
                                                                fontWeight: '400',
                                                                height: 'unset',
                                                            }
                                                        }}>
                                                            Furnishing Type
                                                            <svg style={{
                                                                transform: uisShown.includes("furnishing-type") ? "rotate(180deg)" : "rotate(0deg)",
                                                            }} css={{
                                                                transition: '0.2s',
                                                                display: 'none',
                                                                [breakpoints.tb]: {
                                                                    display: 'block'
                                                                }
                                                            }} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.19995 6.1001L7.99995 10.9001L12.8 6.1001" stroke="#1A1A1A" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round"/>
                                                            </svg>
                                                        </Typography>
                                                        <div css={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: '12px',
                                                            [breakpoints.tb]: {
                                                                flexDirection: 'row',
                                                                flexFlow: 'wrap',
                                                                display: uisShown.includes('furnishing-type') ? 'flex' : 'none',
                                                                paddingTop: '20px',
                                                            }
                                                        }}>
                                                            {Object.values(furnishingTypeGroup.options).map(({ title, invertImage, image, id, parentId, selected }) => {

                                                                const maybeResults = filterProducts(productDataSource, {
                                                                    ...currentFilters,
                                                                    [parentId]: [id]
                                                                })


                                                                if (maybeResults.length === 0) {
                                                                    return null
                                                                }
                                                                return (
                                                                    <div>
                                                                        <StyledButton size="sm" disabled={maybeResults.length === 0} css={{
                                                                            padding: '6px 14px',
                                                                            transition: 'background-color 0.2s, opacity 0.2s',
                                                                            '&:disabled': {
                                                                                opacity: 0.5
                                                                            }
                                                                        }} className={selected ? "filled-orange" : "filled-white"} onClick={() => {
                                                                            applyFilter(furnishingTypeGroup.id, id)
                                                                        }}>
                                                                            <div css={{ display: 'flex', gap: '8px' }}>
                                                                                <div css={{
                                                                                    width: '20px',
                                                                                    height: '20px',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    'path': {
                                                                                        strokeWidth: (selected && maybeResults.length > 0) ? '2px' : '1px'
                                                                                    }
                                                                                }} dangerouslySetInnerHTML={{
                                                                                    __html: icons[(selected && maybeResults.length > 0) ? invertImage.url : image.url]
                                                                                }}/>
                                                                                {title}
                                                                            </div>
                                                                        </StyledButton>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                                {!hideCategoryUi && (
                                                    <div css={{
                                                        width: '20%',
                                                        overflowY: 'auto',
                                                        maxHeight: '314px',
                                                        [breakpoints.tb]: {
                                                            width: '100%',
                                                        }
                                                    }}>
                                                        <Typography fontSize={{
                                                            dt: "p1"
                                                        }} role="button" onClick={() => {
                                                            if (uisShown.includes("category")) {
                                                                setUisShown((e) => e.filter(x => x !== "category"))
                                                            } else {
                                                                setUisShown((e) => [...e, "category"])
                                                            }
                                                        }} color="shadesBlack" css={{
                                                            width: '100%',
                                                            paddingBottom: '12px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            fontWeight: '500',
                                                            height: '33px',
                                                            backgroundColor: colors.secondaryBeige2,
                                                            position: 'sticky',
                                                            zIndex: '2',
                                                            top: '0',
                                                            [breakpoints.tb]: {
                                                                borderBottom: `1px solid ${colors.secondaryBeige4}`,
                                                                fontWeight: '400',
                                                            }
                                                        }}>
                                                            Category
                                                            <svg style={{
                                                                transform: uisShown.includes("category") ? "rotate(180deg)" : "rotate(0deg)",
                                                            }} css={{
                                                                transition: '0.2s',
                                                                display: 'none',
                                                                [breakpoints.tb]: {
                                                                    display: 'block'
                                                                }
                                                            }} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.19995 6.1001L7.99995 10.9001L12.8 6.1001" stroke="#1A1A1A" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round"/>
                                                            </svg>
                                                        </Typography>
                                                        <div css={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: '12px',
                                                            [breakpoints.tb]: {
                                                                display: uisShown.includes('category') ? 'flex' : 'none',
                                                                paddingTop: '20px',
                                                            }
                                                        }}>
                                                            {Object.values(productGroup.options).map(({ title, id, selected, parentId }) => {
                                                                const maybeResults = filterProducts(productDataSource, {
                                                                    ...currentFilters,
                                                                    [parentId]: [id]
                                                                })

                                                                if (maybeResults.length === 0) {
                                                                    return null
                                                                }

                                                                return  (
                                                                    <AnimatedCheckbox size="sm" disabled={maybeResults.length === 0} theme="orange" checked={(selected && maybeResults.length > 0)} setChecked={() => {
                                                                        applyFilter(productGroup.id, id)
                                                                    }}>
                                                                        <div css={{
                                                                            textAlign: 'left'
                                                                        }}>
                                                                            {title}
                                                                        </div>
                                                                    </AnimatedCheckbox>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                                {entryTags.map(([key, facet]) => {

                                                    if (key === "**PRODUCT" || key === "**FURNISHING_TYPE") {
                                                        return null
                                                    }

                                                    return (
                                                        <div css={{
                                                            width: '20%',
                                                            [breakpoints.tb]: {
                                                                width: '100%',
                                                            }
                                                        }}>
                                                            <Typography fontSize={{
                                                                dt: "p1"
                                                            }} role="button" onClick={() => {
                                                                if (uisShown.includes(facet.title)) {
                                                                    setUisShown((e) => e.filter(x => x !== facet.title))
                                                                } else {
                                                                    setUisShown((e) => [...e, facet.title])
                                                                }
                                                            }} color="shadesBlack" css={{
                                                                width: '100%',
                                                                paddingBottom: '12px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                fontWeight: '500',
                                                                height: '33px',
                                                                [breakpoints.tb]: {
                                                                    borderBottom: `1px solid ${colors.secondaryBeige4}`,
                                                                    fontWeight: '400',
                                                                    height: 'unset',
                                                                }
                                                            }}>
                                                                {facet.title}
                                                                <svg style={{
                                                                    transform: uisShown.includes(facet.title) ? "rotate(180deg)" : "rotate(0deg)",
                                                                }} css={{
                                                                    transition: '0.2s',
                                                                    display: 'none',
                                                                    [breakpoints.tb]: {
                                                                        display: 'block'
                                                                    }
                                                                }} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.19995 6.1001L7.99995 10.9001L12.8 6.1001" stroke="#1A1A1A" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round"/>
                                                                </svg>
                                                            </Typography>
                                                            <div css={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: '12px',
                                                                [breakpoints.tb]: {
                                                                    display: uisShown.includes(facet.title) ? 'flex' : 'none',
                                                                    paddingTop: '20px',
                                                                }
                                                            }}>
                                                                {Object.values(facet.options).map((option) => {

                                                                    const maybeResults = filterProducts(productDataSource, {
                                                                        ...currentFilters,
                                                                        [option.parentId]: [option.id]
                                                                    })

                                                                    if (maybeResults.length === 0) {
                                                                        return null
                                                                    }

                                                                    return (
                                                                        <AnimatedCheckbox size="sm" theme="orange" disabled={maybeResults.length === 0} checked={option.selected && maybeResults.length > 0} setChecked={() => {
                                                                            applyFilter(facet.id, option.id)
                                                                        }}>
                                                                            {option.title}
                                                                        </AnimatedCheckbox>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div css={{
                                                    display: 'none',
                                                    [breakpoints.tb]: {
                                                        display: 'block',
                                                        marginTop: '48px'
                                                    }
                                                }}>
                                                    <div>
                                                        <Typography fontSize={{
                                                            dt: "p2"
                                                        }} role="button" css={StyledLinkStyles} onClick={() => {
                                                            setCurrentFilters({})
                                                        }}>
                                                            Clear Filters
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div css={{
                                                display: 'flex',
                                                padding: '20px',
                                                gap: '12px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderTop: `1px solid ${colors.secondaryBeige4}`,
                                                position: 'relative',
                                            }}>
                                                <StyledButton css={{
                                                    width: '180px',
                                                    [breakpoints.tb]: {
                                                        width: '100%',
                                                    }
                                                }} className="filled-orange" onClick={() => {
                                                    setShow(false)
                                                    setUisShown([])
                                                }} disabled={activeFilters.length === 0}>
                                                    Show Selected
                                                </StyledButton>
                                                <StyledButton css={{
                                                    width: '180px',
                                                    [breakpoints.tb]: {
                                                        width: 'unset',
                                                        minWidth: '126px',
                                                    }
                                                }} className="outlined-grey" onClick={() => {
                                                    setShow(false)
                                                    setUisShown([])
                                                }}>
                                                    Close
                                                </StyledButton>
                                                <div css={{
                                                    position: 'absolute',
                                                    right: '30px',
                                                    height: '100%',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    display: 'flex',
                                                    [breakpoints.tb]: {
                                                        display: 'none'
                                                    }
                                                }}>
                                                    <Typography fontSize={{
                                                        dt: "p2"
                                                    }} role="button" css={StyledLinkStyles} onClick={() => {
                                                        setCurrentFilters({})
                                                    }}>
                                                        Clear Filters
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Background>
                                    </div>
                                </div>
                            </AnimateChangeInHeight>
                        </div>
                    </div>
                    <hr css={{
                        borderTop: `1px solid ${colors.secondaryBeige4}`,
                        width: '100%',
                        transition: '0.4s',
                    }} style={{
                        opacity: show ? 0 : 1,
                        transform: show ? 'translateX(50%)' : 'translateX(0%)',
                        width: show ? '0%' : '100%',
                    }} />
                    {activeFilters.length > 0 && (
                        <div css={{
                            marginTop: '20px',
                            display: 'flex',
                            gap: '12px',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            [breakpoints.tb]: {
                                marginBottom: '28px'
                            }
                        }}>
                            {activeFilters.map((filter) => (
                                <div css={[styledButtonBaseStyles, {
                                    padding: '6px 14px'
                                }]} className="filled-beige">
                                    <div css={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                    }}>
                                        {filter?.image && (
                                            <div css={{
                                                width: '19px',
                                                height: '19px',
                                                marginRight: '8px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }} dangerouslySetInnerHTML={{
                                                __html: icons[filter.image.url]
                                            }} />
                                        )}
                                        <div>{filter.title}</div>
                                        <button type="button" onClick={() => {
                                            applyFilter(filter.parentId, filter.id)
                                        }}>
                                            <svg css={{
                                                marginLeft: '12px'
                                            }} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11 1L1 11M1 1L11 11" stroke="#1A1A1A" strokeWidth="0.666667" strokeLinecap="square" strokeLinejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </Container>
            )}
            <Container css={{
                marginTop: '20px',
                marginBottom: '56px',
                [breakpoints.tb]: {
                    marginTop: '16px',
                    marginBottom: '28px'
                }
            }}>
                <Row css={{
                    rowGap: '28px'
                }}>
                    {nodesSliced.map((item, idx) => (
                        <Col key={idx} breakpoints={{
                            dt: { span: 3 },
                            tb: { span: 6 },
                            mb: { span: 4 }
                        }}>
                            <ProductCard applyFilters product={item} isH2 />
                        </Col>
                    ))}
                </Row>
            </Container>
            {(hasFilterOptions || (pageCount > 1)) && (<Pagination pages={pageCount} currentPage={page} onChange={(p) => {
                //navigate(page > 1 ? `${shopAllPage.permalink}/${page}` : shopAllPage.permalink)
                setOffset((p) * perPage)
                if (resultsRef?.current) {
                    const { top, height } = resultsRef.current.getBoundingClientRect()
                    window.scrollTo({
                        behavior: "instant",
                        top: top + window.scrollY - height - 40,
                        left: 0,
                    })
                }
            }} />)}
        </div>
    )
}

export const query = graphql`
    fragment ProductListProps on DatoCmsProductList {
        id
        linkedProducts {
            originalId
        }
    }
`

export default ProductList
